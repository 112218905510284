@import 'src/styles/shared';

.slide-box-container {


  opacity: 0;
  transition: all $transition-delay-300;

  &.slide-box-visible {
    opacity: 1;
    transform: translateY(0) !important;
    pointer-events: auto;
  }

  &.scrolling-down {
    transform: translateY(100%);
  }
  &.scrolling-up {
    transform: translateY(-100%);
  }
}