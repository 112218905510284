// duplicated from _functions.scss to prevent circular dependency
@function px-to-rem($size, $pxRef: $base-font-size) {
  $rem-size: $size / $pxRef;

  @return #{$rem-size}rem;
}

// Main Colors
// SKALEONE COLORS
$dark-grey: #262B2A;
$beige: #E6DAC2;
$yellow: #E0BE54;
$blue: #638E9A;
$red: #DE492B;

// CEVA COLORS TODO REMOVE
$blue-light: #5895CC;
$blue-medium: #3B4569;
$blue-dark: #193251;

$grey-lighter: #F6F7F9;
$grey-light: #D8D9DB;
$grey-dark: #474747;
$grey-border: #707070;
$grey-placeholder: #969798;

$new: #1D8039;
$warning: #CC5858;

// Dimensions
$base-font-size: 16px;
$header-height: 0px; // no header, 'px' mandatory, calc() wont work otherwise

// Transitions
$transition-delay-fast: 0.1s;
$transition-delay-300: 0.3s;
$transition-delay-slow: 2s;

//#region Buttons

// Light (standard buttons)
$button-light-background: white;
$button-light-background-hover: rgba($blue-light, 0.2); // 33 => 0.2 // #deeaf5 if opaque
$button-light-background-active: rgba($blue-light, 0.5); // 80 => 0.5 // #abcae5 if opaque

// Dark (big dark buttons in Main & LanguageEditor)
$button-dark-background: $blue-dark; // TODO adapt to user role => $blue-medium for SALES
$button-dark-background-hover: rgba($blue-dark, 0.75); // BF => 0.75 // TODO adapt to user role => $blue-medium for SALES
$button-dark-background-active: white;

//#endregion

//#region Header

// Background
$customer-header-color: $blue-light;
$sales-header-color: $blue-medium;
$admin-header-color: $blue-dark;

// Title
$customer-room-name-color: $grey-dark;
$sales-room-name-color: $grey-dark;
$admin-room-name-color: $grey-dark;
$configuration-name-color: $blue-dark;

$icon-back-color: $blue-dark;
$icon-fore-color: #FFFFFF;

//#endregion

//#region Easing

$ease: (
  in-quad:      cubic-bezier(0.550,  0.085, 0.680, 0.530),
  in-cubic:     cubic-bezier(0.550,  0.055, 0.675, 0.190),
  in-quart:     cubic-bezier(0.895,  0.030, 0.685, 0.220),
  in-quint:     cubic-bezier(0.755,  0.050, 0.855, 0.060),
  in-sine:      cubic-bezier(0.470,  0.000, 0.745, 0.715),
  in-expo:      cubic-bezier(0.950,  0.050, 0.795, 0.035),
  in-circ:      cubic-bezier(0.600,  0.040, 0.980, 0.335),
  in-back:      cubic-bezier(0.600, -0.280, 0.735, 0.045),
  out-quad:     cubic-bezier(0.250,  0.460, 0.450, 0.940),
  out-cubic:    cubic-bezier(0.215,  0.610, 0.355, 1.000),
  out-quart:    cubic-bezier(0.165,  0.840, 0.440, 1.000),
  out-quint:    cubic-bezier(0.230,  1.000, 0.320, 1.000),
  out-sine:     cubic-bezier(0.390,  0.575, 0.565, 1.000),
  out-expo:     cubic-bezier(0.190,  1.000, 0.220, 1.000),
  out-circ:     cubic-bezier(0.075,  0.820, 0.165, 1.000),
  out-back:     cubic-bezier(0.175,  0.885, 0.320, 1.275),
  in-out-quad:  cubic-bezier(0.455,  0.030, 0.515, 0.955),
  in-out-cubic: cubic-bezier(0.645,  0.045, 0.355, 1.000),
  in-out-quart: cubic-bezier(0.770,  0.000, 0.175, 1.000),
  in-out-quint: cubic-bezier(0.860,  0.000, 0.070, 1.000),
  in-out-sine:  cubic-bezier(0.445,  0.050, 0.550, 0.950),
  in-out-expo:  cubic-bezier(1.000,  0.000, 0.000, 1.000),
  in-out-circ:  cubic-bezier(0.785,  0.135, 0.150, 0.860),
  in-out-back:  cubic-bezier(0.680, -0.550, 0.265, 1.550)
);

//#endregion
