@import './variables';
@import './mixins';

.button-dark {
  height: 100%;
  object-fit: contain;

  @include fill('circle', $blue-dark);
  @include fill('path', #FFFFFF);

  &:active {
    cursor: pointer;
    @include fill('circle', $blue-light);
    @include fill('path', #FFFFFF);
  }
}

.button-light {
  height: 100%;
  object-fit: cover;

  @include fill('circle', #FFFFFF);
  @include fill('path', $blue-dark);

  &:active {
    cursor: pointer;
    @include fill('circle', $blue-light);
    @include fill('path', #FFFFFF);
  }
}

.button-light-stroke {
  height: 100%;
  object-fit: contain;

  @include fill('path', $blue-dark);

  &:active {
    cursor: pointer;
    @include fill('path', $blue-light);
  }
}


@media (hover: hover) and (pointer: fine) {
  .button-dark {
    &:hover {
      cursor: pointer;
      @include fill('circle', #FFFFFF);
      @include fill('path, text', $blue-dark);
    }

    * {
      transition: $transition-delay-fast ease-out;
    }
  }

  .button-light {
    &:hover {
      cursor: pointer;
      @include fill('circle', $blue-dark); // $blue-medium
      @include fill('path', #FFFFFF);
    }

    * {
      transition: $transition-delay-fast ease-out;
    }
  }

  .button-light-stroke {
    &:hover {
      cursor: pointer;
      @include fill('path', $blue-light);
    }
  }
}


.button {
  display: inline-block;

  @include font-sans-serif();
  font-size: px-to-rem(24px);
  font-weight: 600;
  text-transform: uppercase;

  padding: px-to-rem(15px) px-to-rem(20px);
  text-align: center;

  transition: $transition-delay-300 background-color ease(out-cubic);

  &:disabled {
    background-color: $grey-lighter !important;
    color: $grey-placeholder !important;
  }

  &.light { // standard buttons
    min-height: px-to-rem(60px);
    color: $blue-light;
    background: $button-light-background;
    border-radius: px-to-rem(13px);
    border: 1px solid $blue-light;

    &:disabled {
      background-color: $grey-lighter;
    }

    &:not(:disabled) {
      &:hover {
        background-color: $button-light-background-hover;
        cursor: pointer;
      }

      &:active {
        background-color: $button-light-background-active !important;
        color: white !important;
        box-shadow: inset 1px 1px 3px rgba(black, 0.3);
      }
    }
  }

  &.dark {
    width: 100%;
    background-color: $button-dark-background;
    border: none;

    @include font-serif();
    color: white;
    text-transform: capitalize;
    font-weight: 400;

    border-radius: px-to-rem(30px);
    padding: px-to-rem(36px);

    &:not(:disabled) {
      &:hover {
        background-color: $button-dark-background-hover;
        cursor: pointer;
      }
    }

    //&:active {
    //  background-color: $button-dark-background-active !important;
    //  color: red;
    //  box-shadow: inset 1px 1px 3px rgba(black, 0.3);
    //}
  }

  &.secondary { // big dark buttons for Main & Language Editor
    // TODO
    color: $grey-dark;
    text-transform: initial;
    font-weight: 400;
    text-decoration: underline;
    font-size: px-to-rem(14px);
    padding: 0;

    &:not(:disabled) {
      &:hover {
        font-weight: bold;
        font-style: italic;
        cursor: pointer;
      }
    }

    &:disabled {
      background-color: $grey-lighter;
    }
  }
}