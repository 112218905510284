@import 'src/styles/shared';

.webgl-canvas {
  //background-color: #ff000080 !important;

  //display: inline-block;
  position: absolute; // needed otherwise Canvas canvas doesn't shrink when reducing height
  //flex-grow: 1;

  height: 100%;
  width: 100%;
  top: 0;

  //&.desktop {
  //
  //}
  //&.tablet {
  //
  //}
  //&.mobile {
  //
  //}

  transition: filter $transition-delay-300;
  &.blurred {
    filter: blur(10px);
  }

  &.disabled {
    pointer-events: none;
  }
}

.stats, .stats > * {
  // uncomment below to dock Stats top-right
  top: unset !important;
  bottom: 20px !important;
  left: 0 !important;
  right: unset !important;
  //zoom: 1.5;
}