@import 'src/styles/shared';

.background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  transition: all 1s;

  &.tablet {
    svg {
      width: px-to-rem(1280px);
    }
  }

  &.mobile {
    svg {
      width: px-to-rem(750px);
      transition: all 1.5s ease-in-out;

      * {
        transition: all 1.5s ease-in-out;
      }

      &.close {
        width: px-to-rem(1000px);

        g#planete {
          transform: translateY(600px);
        }

        g#orbite_satellite {
          transform: translate(-450px, 1000px) !important;

          g#satellite {
            transform: translate(-3680px, -2045px) scale(4);
          }
        }
      }
    }
  }

  svg {
    position: absolute;
    width: px-to-rem(1920px);
    height: auto;
    bottom: 0;
    right: 0;

    g#planete {
      transform: none;
    }

    g#orbite_satellite {
      transform: none;

      path#arrow {
        cursor: pointer;
      }

      g#satellite {
        transform: none;
        cursor: pointer;

        &:hover {
          transform: translate(10px, -2px);
        }
      }
    }

    * {
      transition: all 1.5s ease-in-out;
    }

    &.close {
      g#planete {
        transform: translate(400px, 450px);
      }

      path#arrow {
        opacity: 0;
        transition: all 1s linear;
      }

      g#orbite_satellite {
        transform: translate(-450px, 100px) !important;


        g#satellite {
          transform: translate(-3934px, -1084px) scale(4);
        }
      }
    }
  }

}