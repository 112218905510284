@import 'src/styles/shared';

.product-sheet-contact-link {
  position: fixed;
  z-index: 2000;
  transition: opacity $transition-delay-300;
  opacity: 0;
  pointer-events: none;

  &.visible {
    transition: opacity $transition-delay-300;
    opacity: 1;
  }

  &.desktop,
  &.tablet {
    top: 0;
    right: 0;
    width: px-to-rem(317px);
    height: px-to-rem(170px);
    background: center / contain no-repeat url("../../../../public/assets/img/contact_background_line_desktop.png");
  }

  &.mobile {
    bottom: 0;
    text-align: center;
    width: 100%;
    height: px-to-rem(48px);
    background: center / contain no-repeat url("../../../../public/assets/img/contact_background_line_mobile.png");
  }

  .product-sheet-contact-link-text {
    pointer-events: auto;
    position: absolute;

    display: flex;
    justify-content: space-between;
    align-items: center;


    height: px-to-rem(30px);


    * {
      line-height: px-to-rem(30px);
      height: px-to-rem(30px);
      fill: $dark-grey;
      color: $dark-grey;
    }

    &:hover {
      cursor: pointer;
    }

    &.desktop,
    &.tablet {
      width: px-to-rem(120px);
      font-size: px-to-rem(24px);
      right: px-to-rem(93px);
      top: px-to-rem(82px);
    }

    &.mobile {
      width: px-to-rem(110px);
      font-size: px-to-rem(22px);
      left: px-to-rem(140px);
      right: px-to-rem(140px);
      bottom: px-to-rem(10px);
    }
  }
}

.product-sheet-contact-container {
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;

  &.visible {
    pointer-events: auto;
  }
}

.product-sheet-contact-overlay {
  z-index: 1300;
  transform: translateY(100%);
  opacity: 0;
  pointer-events: none;

  overflow: hidden;
  background-color: $blue;

  transition: all $transition-delay-300;

  &.desktop,
  &.tablet {
    padding: px-to-rem(60px) px-to-rem(40px);
  }

  &.mobile {
    padding: px-to-rem(70px) px-to-rem(40px);
  }

  &.visible {
    transform: translateY(0%);
    opacity: 1;
    pointer-events: auto;
    transition: all $transition-delay-300;
  }

  .product-sheet-contact-overlay-close {
    position: absolute;

    &.desktop,
    &.tablet {
      top: px-to-rem(12px);
      right: px-to-rem(12px);
    }

    &.mobile {
      top: px-to-rem(11px);
      right: px-to-rem(10px);
    }
  }

  .product-sheet-contact-overlay-title {
    @include font-sans-serif();
    color: $beige;
    //font-weight: 700;
    letter-spacing: 1.04px;
    //text-transform: uppercase;
    text-decoration: underline;
    line-height: px-to-rem(50px);

    &.desktop,
    &.tablet {
      font-size: px-to-rem(26px);
    }

    &.mobile {
      font-size: px-to-rem(22px);
    }
  }

  .product-sheet-contact-overlay-content {
    @include font-sans-serif();
    font-size: px-to-rem(20px);
    line-height: px-to-rem(25px);
    letter-spacing: px-to-rem(1.02px);
    color: $beige;

    //position: absolute;
    //overflow-y: auto;

    input {
      height: px-to-rem(40px);
    }

    textarea {
      height: px-to-rem(207px);
    }

    textarea, input {
      width: 100%;
      margin: px-to-rem(9px) px-to-rem(-5px);
      @include font-sans-serif();
      font-size: px-to-rem(20px);
      padding: px-to-rem(8px);
      background-color: $beige;
      border: 0;
      border-radius: px-to-rem(5px);
    }

    &.desktop,
    &.tablet {
      top: px-to-rem(92px);
      bottom: px-to-rem(60px);
      right: px-to-rem(40px);
      left: px-to-rem(40px);
    }

    &.mobile {
      top: px-to-rem(92px);
      bottom: px-to-rem(85px);
      right: px-to-rem(20px);
      left: px-to-rem(20px);
    }
  }

  .product-sheet-contact-overlay-submit {
    color: $beige;
    text-decoration: underline;
    line-height: px-to-rem(50px);
    text-align: right;

    &.desktop,
    &.tablet {
      font-size: px-to-rem(26px);
    }

    &.mobile {
      font-size: px-to-rem(22px);
    }
  }
}