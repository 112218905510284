.debug-links-container {
  z-index: 800;
  position: fixed;
  top: 0;

  display: flex;

  .debug-links-help {
    //vertical-align: middle;
    font-size: 0.75rem;
    color: #0DADFF;
  }

  .debug-links-button {
    line-height: 1rem;
    padding: 1rem;
    color: #FFC300;
    background-color: #00000040 !important;
    font-weight: 400;
    //transform: scaleX(110%);
    text-decoration: none;

    &.disabled {
      color: #0DADFF;
      border-bottom: solid 4px #0DADFF;
      pointer-events: none;
    }

    &:hover {
      cursor: pointer;
      background-color: #000000ff;
      border-bottom: solid 1px #FFC300;
    }
  }
}

