@import 'src/styles/shared';

.product-sheet-logo {
  z-index: 999;
  position: absolute;

  height: px-to-rem(48px);
  width: auto;

  color: $beige;

  .product-sheet-logo-png {
    width: 100%;
    height: 100%;
  }

  .product-sheet-logo-svg {
    width: 100%;
    height: 100%;

    & * {
      fill: $beige;
      //shape-rendering: geometricPrecision;
    }
  }

  &.desktop,
  &.tablet {
    top: px-to-rem(86px);
    left: px-to-rem(160px);
  }
  &.mobile {
    top: px-to-rem(52px);
    left: px-to-rem(26px);
  }
}