@import 'src/styles/shared';

.debug-overlay {
  z-index: 99999;
  position: fixed;

  bottom: 20px + 48px; // above fps counter

  pointer-events: none;

  //border-radius: 0 0 1rem 0;
  padding: .5rem;
  background-color: black; //rgba(black, 0.25);
  color: white;
  opacity: 0.25;

  font-family: 'JetBrains Mono', monospace;
  font-weight: 100;
  font-size: 1rem;

  &.mobile {
    font-size: .75rem;
  }

  .debug-item {
    .debug-item-value {
      font-weight: 700;
      padding: 0 .5rem;
      border-radius: 1rem;

      &.flash {
        animation: flash-animation $transition-delay-slow;
        -webkit-animation: flash-animation $transition-delay-slow;
      }

      @-webkit-keyframes flash-animation {
        0% { background-color: red; }
        20% { background-color: red; }
        to   { background-color: inherit; }
      }
      @keyframes flash-animation {
        0% { background-color: lime; }
        20% { background-color: red; }
        to   { background-color: inherit; }
      }
    }
    //clear: both;
  }
}