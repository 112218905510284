// TODO REMOVE Nunito Sans
//  left here for svg text... => remove text from svg or vectorize it

// 300 LIGHT
@font-face {
  font-family: 'Nunito Sans';
  src:  url('./assets/fonts/NunitoSans-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito Sans';
  src:  url('./assets/fonts/NunitoSans-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

// 400 REGULAR
@font-face {
  font-family: 'Nunito Sans';
  src:  url('./assets/fonts/NunitoSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito Sans';
  src:  url('./assets/fonts/NunitoSans-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

// 600 SEMIBOLD
@font-face {
  font-family: 'Nunito Sans';
  src:  url('assets/fonts/NunitoSans-Semibold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito Sans';
  src:  url('assets/fonts/NunitoSans-SemiboldItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

// 700 BOLD
@font-face {
  font-family: 'Nunito Sans';
  src:  url('./assets/fonts/NunitoSans-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito Sans';
  src:  url('./assets/fonts/NunitoSans-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

// 900 BLACK
@font-face {
  font-family: 'Nunito Sans';
  src:  url('./assets/fonts/NunitoSans-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Nunito Sans';
  src:  url('./assets/fonts/NunitoSans-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}