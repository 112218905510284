@import 'src/styles/shared';

.spinner {
  animation: spinner-rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  width: 50px;
  height: 50px;

  & .path {
    //stroke: $blue;
    stroke-linecap: round;
    animation: spinner-dash 1.5s ease-in-out infinite;
  }

}

@keyframes spinner-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spinner-dash {
  0% {
    stroke: $yellow;
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke: $blue;
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke: $yellow;
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
