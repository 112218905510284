@import './variables';
@import './functions';
@import './mixins';

.hidden {
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
}

.visible { // TODO test
  opacity: 1;
  transition: opacity ease-in-out .5s;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
}

.fill-parent {
  width: 100%;
  height: 100%;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-center-horizontal {
  justify-content: center;
}

.flex-align-top {
  align-items: flex-start !important;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

// any input or textarea placeholder
:placeholder-shown {
  color: $grey-light;
  font-style: italic;
}

.force-size-expand {
  width: 100% !important;
  height: 100% !important;
}

.border {
  border: 1px solid $grey-border;
}

.radius-15 { // TODO make a function / mixin to set px
  //border-radius: 15px;
  border-radius: px-to-rem(15px);
}

.radius-30 {
  //border-radius: 30px;
  border-radius: px-to-rem(30px);
}

.form {
  display: flex;
  flex-flow: column;

  & * {
    @include font-sans-serif();
  }

  & input {
    padding: 0.5rem;
    border: none;
    font-size: px-to-rem(20px);
    width: px-to-rem(560px);
    margin-top: 1rem;
    background-color: $grey-lighter !important;

    &::placeholder {
      font-size: px-to-rem(20px);
      color: $grey-placeholder;
    }
  }

  & button {
    //  padding: 0.5rem;
    //  font-size: px-to-rem(30px);
    //  line-height: px-to-rem(43px);
    width: px-to-rem(160px);
    //  font-weight: 600;
    align-self: center;
    margin-top: px-to-rem(50px);
    //  border: solid 1px $blue-light;
    //  border-radius: px-to-rem(13px);
    //
    //  background-color: #FFFFFF;
    //  color: $blue-light;
    //  transition: $transition-delay-fast background-color ease-out;
    //
    //  &:disabled {
    //    background-color: $grey-lighter;
    //  }
    //
    //  &:hover:not(:disabled){
    //    background-color: $button-light-background-hover;
    //    cursor: pointer;
    //  }
  }
}

.section-title {
  font-size: px-to-rem(22px);
  line-height: px-to-rem(29px);
  font-weight: 400;
  @include font-serif();
  color: $blue-light;
}

.missing-field-warning {
  // TODO missing-field-warning
}

.floating-overlay-box {
  position: fixed;

  &.desktop,
  &.tablet {
    top: px-to-rem(314px);
    left: px-to-rem(160px);
    width: px-to-rem(640px);
    height: px-to-rem(520px);
  }

  &.mobile {
    top: px-to-rem(128px);
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.clickable {
  pointer-events: auto;

  &:hover {
    cursor: pointer;
  }
}